"use client";
import React, { useRef } from "react";
import { useUser } from "@/context/AuthContext";

import Link from "next/link";
import { useRouter } from "next/router";
import { useMutation } from "@tanstack/react-query";

import NotificationCard from "@/components/Notification/NotificationCard";
import Logo from "@/assets/logo/logo.png";

import { StyleClass } from "primereact/styleclass";
import { Skeleton } from "primereact/skeleton";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";

import { useMessageData } from "@/hooks/useMessageData";
import { useUserData } from "@/hooks/useUserData";

import { AuthServices } from "@/services/auth.services";
import { NotificationServices } from "@/services/notification.services";
import { useFeatureAccess } from "@/hooks/useFeatureAccess";
import { IAccessLevelRecruiter } from "@/types/formInfo.interface";
import useNotifications from "@/hooks/UseNotifications";

const Header = () => {
  const { isLogin, currentUser } = useUser();
  const { userData, completedProfile, loading } = useUserData(
    currentUser?.uid,
    currentUser?.userType
  );
  const { messageNotRead } = useMessageData(currentUser?.uid);
  const { unreadNotifications } = useNotifications();

  const { hasAccess: accessToMessaging } = useFeatureAccess([
    process.env.recruiterFullAccessLevel,
  ] as IAccessLevelRecruiter[]);

  const showMessage = accessToMessaging || currentUser?.userType === "talent";

  const { hasAccess: accessToHeaderMenu } = useFeatureAccess([
    process.env.recruiterFullAccessLevel,
    process.env.recruiterTrialAccessLevel,
  ] as IAccessLevelRecruiter[]);

  const router = useRouter();
  const btnRef1 = useRef(null);
  const btnRef2 = useRef(null);

  const userMenu = useRef(null);

  const MenuSkeleton = () => {
    return (
      <div className="flex align-items-center gap-2">
        <Skeleton width="5rem" height="1rem"></Skeleton>
        <Skeleton width="5rem" height="1rem"></Skeleton>
      </div>
    );
  };

  const RightMenuSkeleton = () => {
    return (
      <div className="flex align-items-center gap-4">
        <Skeleton width="2rem" height="2rem"></Skeleton>
        <Skeleton width="2rem" height="2rem"></Skeleton>
        <Skeleton
          width="2rem"
          height="2rem"
          className="border-circle"
        ></Skeleton>
      </div>
    );
  };

  const MenuRecruiterTemplate = () => {
    return (
      <ul className="list-none p-0 m-0 flex select-none flex-column lg:flex-row">
        <li>
          <Link
            href="/dashboard/recruiter"
            className={`${
              router.asPath == "/dashboard/recruiter/"
                ? "text-primary-500 border-bottom-2 border-primary-500"
                : ""
            } text-primary flex p-3 h-full lg:px-3 lg:py-5 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary-500 font-medium cursor-pointer transition-colors transition-duration-150 no-underline text-primary-600`}
          >
            <i className="pi pi-home mr-2"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        <li>
          <Link
            className={`${
              router.asPath == "/dashboard/recruiter/jobs/"
                ? "text-primary-500 border-bottom-2 border-primary-500"
                : ""
            } flex p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary-500 font-medium cursor-pointer transition-colors transition-duration-150 no-underline text-primary-600`}
            href="/dashboard/recruiter/jobs"
          >
            <span>Jobs</span>
          </Link>
        </li>
        <li>
          <Link
            className={`${
              router.asPath == "/dashboard/recruiter/talents/"
                ? "text-primary-500 border-bottom-2 border-primary-500"
                : ""
            } flex p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary-500 font-medium cursor-pointer transition-colors transition-duration-150 no-underline text-primary-600`}
            href="/dashboard/recruiter/talents"
          >
            <span>Talents</span>
          </Link>
        </li>
      </ul>
    );
  };

  const MenuTalentTemplate = () => {
    return (
      <ul className="list-none p-0 m-0 flex select-none flex-column lg:flex-row">
        <li>
          <Link
            href="/dashboard/talent/"
            className={`${
              router.asPath == `/dashboard/talent/`
                ? "text-primary border-bottom-2 border-primary-500"
                : ""
            } flex p-3 h-full lg:px-3 lg:py-5 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary-500 font-medium cursor-pointer transition-colors transition-duration-150 no-underline text-primary-600`}
          >
            <i className="pi pi-home mr-2"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        <li>
          <Link
            href="/stories"
            className={`${
              router.asPath == `/stories/`
                ? "text-primary border-bottom-2 border-primary-500"
                : ""
            } flex p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary-500 font-medium cursor-pointer transition-colors transition-duration-150 no-underline text-primary-600`}
          >
            <span>Stories</span>
          </Link>
        </li>
      </ul>
    );
  };

  const UserMenuAndAvatar = () => {
    return (
      <li className="border-top-1 surface-border lg:border-top-none flex relative">
        <StyleClass
          nodeRef={userMenu}
          selector="@next"
          enterClassName="hidden"
          enterActiveClassName="scalein"
          leaveToClassName="hidden"
          leaveActiveClassName="fadeout"
          hideOnOutsideClick
        >
          <a
            data-id="user-menu"
            ref={userMenu}
            className=" flex  p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
          >
            <Avatar
              image={userData?.avatar}
              shape="circle"
              size="normal"
              className="mr-3 lg:mr-0 border-circle"
              icon={!userData?.avatar && "pi pi-user text-primary"}
            />
          </a>
        </StyleClass>
        <div className="border-round surface-overlay p-3 shadow-2 absolute right-0 top-100 z-5 w-15rem origin-top hidden">
          <div className="text-900 font-medium">
            {userData?.firstName + " " + userData?.lastName}
          </div>
          <ul className="list-none mt-4 p-0 flex flex-column gap-2">
            <li>
              {currentUser?.userType === "talent" ? (
                <Link
                  className="text-700 hover:text-primary no-underline"
                  href="/dashboard/talent/settings"
                >
                  Settings
                </Link>
              ) : (
                <Link
                  className="text-700 hover:text-primary no-underline"
                  href="/onboarding/recruiter"
                >
                  Edit Profile
                </Link>
              )}
            </li>
            <li
              data-id="logout"
              className="cursor-pointer text-700 hover:text-primary"
              onClick={() => AuthServices.logOut()}
            >
              Sign out
            </li>
          </ul>
        </div>
      </li>
    );
  };

  const UserMenuAndAvatarMobile = () => {
    return (
      <li className="border-top-1 surface-border lg:border-top-none relative flex align-items-center p-3">
        <Avatar
          image={userData?.avatar as string}
          shape="circle"
          size="large"
          className="mr-3 lg:mr-0 border-circle"
          icon={!userData?.avatar && "pi pi-user text-primary"}
        />
        <div>
          <div className="text-900 font-medium mb-1">
            {userData?.firstName + " " + userData?.lastName}
          </div>
          <ul className="list-none p-0 flex flex-column gap-1">
            <li>
              {currentUser?.userType === "talent" ? (
                <Link
                  className="text-700 hover:text-primary no-underline"
                  href="/dashboard/talent/settings"
                >
                  Settings
                </Link>
              ) : (
                <Link
                  className="text-700 hover:text-primary no-underline"
                  href="/onboarding/recruiter"
                >
                  Edit Profile
                </Link>
              )}
            </li>
            <li
              className="cursor-pointer text-700 hover:text-primary"
              onClick={() => AuthServices.logOut()}
            >
              Sign out
            </li>
          </ul>
        </div>
      </li>
    );
  };

  const { mutate: mutateReadNotifications } = useMutation(() =>
    NotificationServices.readNotifications(
      currentUser?.uid,
      currentUser?.userType
    )
  );

  //TODO

  return (
    <div
      className="surface-overlay px-2 md:px-6 shadow-2 flex justify-content-between align-items-center relative lg:static"
      style={{ minHeight: "80px" }}
    >
      <div className="flex h-full align-items-between">
        <Link href="/" className="mr-5">
          <img src={Logo.src} alt="logo" className="w-7rem md:w-10rem" />
        </Link>
      </div>
      {loading && !isLogin && (
        <div className="flex justify-content-between w-full">
          <MenuSkeleton />
          <RightMenuSkeleton />
        </div>
      )}
      {!loading && isLogin && (
        <div className="w-full ml-auto">
          <>
            <StyleClass
              nodeRef={btnRef2}
              selector="@next"
              enterClassName="hidden"
              leaveToClassName="hidden"
              hideOnOutsideClick
            >
              <a
                ref={btnRef2}
                className=" cursor-pointer block lg:hidden text-right text-700"
              >
                <i className="pi pi-bars text-4xl"></i>
              </a>
            </StyleClass>

            <div className="flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-3 shadow-2 lg:shadow-none">
              {isLogin && (
                <>
                  {currentUser?.userType === "recruiter" &&
                    completedProfile &&
                    accessToHeaderMenu && <MenuRecruiterTemplate />}
                  {currentUser?.userType === "talent" && completedProfile && (
                    <MenuTalentTemplate />
                  )}
                  {!completedProfile && <div></div>}

                  <ul className="list-none p-0 m-0 flex select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none ml-auto">
                    {completedProfile ? (
                      <>
                        <li className="inline-flex relative">
                          <StyleClass
                            nodeRef={btnRef1}
                            selector="@next"
                            enterClassName="hidden"
                            enterActiveClassName="scalein"
                            leaveToClassName="hidden"
                            leaveActiveClassName="fadeout"
                            hideOnOutsideClick
                          >
                            <a
                              ref={btnRef1}
                              className=" flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors w-full text-primary-500"
                            >
                              {!!unreadNotifications?.length ? (
                                <i
                                  className="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge"
                                  onClick={() => mutateReadNotifications()}
                                >
                                  <Badge severity="danger"></Badge>
                                </i>
                              ) : (
                                <i className="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge"></i>
                              )}

                              <span className="block lg:hidden font-medium">
                                Notifications
                              </span>
                            </a>
                          </StyleClass>
                          <NotificationCard />
                        </li>
                        {showMessage && (
                          <li className="inline-flex relative ">
                            <Link
                              href="/messages"
                              className="flex p-3 align-items-center hover:text-900 hover:surface-100 text-primary-500 border-round transition-duration-150 transition-colors w-full no-underline"
                            >
                              <i className="pi pi-comments text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge">
                                {!!messageNotRead && (
                                  <Badge
                                    severity="danger"
                                    value={messageNotRead}
                                  ></Badge>
                                )}
                              </i>
                              <span className="block lg:hidden font-medium">
                                Messages
                              </span>
                            </Link>
                          </li>
                        )}
                        <div className="lg:hidden">
                          <UserMenuAndAvatarMobile />
                        </div>
                        <div className="hidden lg:inline-flex">
                          <UserMenuAndAvatar />
                        </div>
                      </>
                    ) : (
                      <li
                        className="inline-flex cursor-pointer text-primary-600 text-700 hover:text-primary p-3 md:p-0"
                        onClick={() => AuthServices.logOut()}
                      >
                        Sign out
                      </li>
                    )}
                  </ul>
                </>
              )}
            </div>
          </>
        </div>
      )}
      {!isLogin && (
        <div className="flex">
          <Link
            data-id="login-link"
            href="/auth/login"
            className="text-center w-5rem font-medium no-underline p-2 text-primary border-round-sm border-1 border-primary"
          >
            Sign In
          </Link>
          <Link
            href="/auth/register"
            className="text-center w-5rem font-medium no-underline ml-2 bg-primary p-2 border-round-sm border-1 border-transparent"
          >
            Sign Up
          </Link>
        </div>
      )}
    </div>
  );
};

export default Header;

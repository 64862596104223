import React from "react";

import NotificationItem from "./NotificationItem";
import useNotifications from "@/hooks/UseNotifications";

const NotificationCard = () => {
  const { notifications } = useNotifications();

  return (
    <div className="hidden border-round surface-overlay shadow-2 absolute right-0 top-100 z-2 w-30rem origin-top">
      <div>
        {!!notifications?.length ? (
          notifications?.map((notification, idx) => {
            return (
              <NotificationItem
                notification={notification}
                key={notification?.id}
                noBorder={notifications?.length - 1 === idx}
              />
            );
          })
        ) : (
          <p className="text-primary p-2">
            There are no notifications available for you at the moment.
          </p>
        )}
      </div>
    </div>
  );
};

export default NotificationCard;

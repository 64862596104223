import React, { FC, ReactHTMLElement, useState } from "react";
import { Dialog } from "primereact/dialog";

interface PrivacyAndPolicyModalProps {
  onHide: () => void;
  visible: boolean;
}

const PrivacyAndPolicyModal: FC<PrivacyAndPolicyModalProps> = ({
  visible,
  onHide,
}) => {
  return (
    <Dialog
      draggable={false}
      blockScroll={true}
      visible={visible}
      onHide={onHide}
      header="Privacy Policy"
      className="w-11 lg:w-6"
    >
      <p className="line-height-3">
        Last Updated: September 11, 2023
        <br />
        <br />
        AlertPass Inc. ("we," "our," or "us") operates the "portfolii" job
        marketplace application (the "App"). This Privacy Policy outlines the
        information we collect, how we use it, and the choices you have
        concerning your data when you use our App. By using the App, you consent
        to the practices described in this Privacy Policy.
        <br />
        <br />
        1. Information We Collect
        <br />
        <br />
        a. Information You Provide: When you create an account or use our App,
        we may collect personal information, such as your name, email address,
        phone number, resume, and profile details.
        <br />
        <br />
        b. User-Generated Content: Any content you submit or post on the App,
        including job applications, messages, and comments, may be collected and
        stored.
        <br />
        <br />
        c. Information from Third Parties: We may receive information about you
        from third-party sources, such as social media platforms, when you
        choose to link your social media account to your App profile.
        <br />
        <br />
        d. Usage Data: We collect data about how you use the App, including your
        interactions with job postings, messages, and other App features. This
        may include device information, IP address, browser type, and operating
        system.
        <br />
        <br />
        2. How We Use Your Information
        <br />
        <br />
        a. Provide and Improve Services: We use your information to provide and
        improve our services, including matching talents with job opportunities,
        facilitating communication between talents and recruiters, and enhancing
        the user experience.
        <br />
        <br />
        b. Communications: We may use your contact information to send you
        updates, newsletters, and notifications related to the App. You can opt
        out of these communications at any time.
        <br />
        <br />
        c. Personalization: We may use your data to personalize your experience,
        such as recommending job opportunities or suggesting connections.
        <br />
        <br />
        d. Analytics: We use data analytics to analyze and improve our services,
        troubleshoot issues, and monitor the performance of the App.
        <br />
        <br />
        3. Sharing Your Information
        <br />
        <br />
        a. Talents and Recruiters: Your profile information, including your
        profile details, may be visible to recruiters using the App or those who
        you shared your profile with.
        <br />
        <br />
        b. Service Providers: We may share your information with third-party
        service providers who help us operate the App and deliver our services.
        <br />
        <br />
        c. Legal Compliance: We may disclose your information if required by
        law, court order, or to protect our rights, property, or safety.
        <br />
        <br />
        4. Your Choices
        <br />
        <br />
        a. Account Settings: You can review and update your account information
        in the App's settings.
        <br />
        <br />
        b. Communication Preferences: You can opt out of promotional emails and
        notifications by adjusting your communication preferences in your
        account settings.
        <br />
        <br />
        c. Data Deletion: You can request the deletion of your account and
        associated data by contacting us at talent@alertpass.com.
        <br />
        <br />
        5. Security
        <br />
        <br />
        We take reasonable measures to protect your personal information;
        however, no data transmission over the internet is entirely secure. We
        cannot guarantee the security of your information transmitted through
        the App.
        <br />
        <br />
        6. Children's Privacy
        <br />
        <br />
        The App is not intended for use by individuals under the age of 16. We
        do not knowingly collect or maintain information from children under 16
        years of age.
        <br />
        <br />
        7. Changes to this Privacy Policy
        <br />
        <br />
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page with the updated "Last Updated" date.
        <br />
        <br />
        8. Contact Us
        <br />
        <br />
        If you have questions or concerns about this Privacy Policy or our data
        practices, please contact us at talent@alertpass.com.
        <br />
        <br />
        By using the App, you acknowledge that you have read and agree to this
        Privacy Policy. If you do not agree with the terms of this Privacy
        Policy, please do not use the App.
      </p>
    </Dialog>
  );
};

export default PrivacyAndPolicyModal;

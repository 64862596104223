import { v4 as uuidv4 } from "uuid";

import { database } from "@/firebaseConfig";
import {
  doc,
  setDoc,
  getDoc,
  getDocs,
  collection,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

import {
  IEducation,
  IExperience,
  IJobFunction,
  IIndustryExperience,
  ILanguage,
  ISoftSkills,
  IHardSkills,
  IAchievementCategory,
  ICertificate,
  IJobTarget,
  IStatus,
  IProfile,
} from "@/types/formInfo.interface";

import { UploadFileServices } from "./uploadFile.services";

export class OnboardingServices {
  static async createPortfolioWithAIData(uid: string, portfoliaData: IProfile) {
    try {
      const {
        firstName,
        lastName,
        title,
        city = "",
        country = "",
        contactNumber,
        bio = "",
        stories = [],
        softSkills = [],
        hardSkills = [],
        educations = [],
        experiences = [],
        certificates = [],
      } = portfoliaData;
      const talentsRef = collection(database, "talents");
      const userRef = doc(talentsRef, uid);
      const profileRef = doc(talentsRef, uid, "profiles", uid);

      await setDoc(
        userRef,
        {
          firstName,
          lastName,
          contactNumber,
          city,
          country,
          status: process.env.onboardingCompletedStatus,
          ...(stories.length && {
            stories,
          }),
          AIcreated: true,
        },

        { merge: true }
      );

      return await setDoc(
        profileRef,
        {
          title,
          bio,
          softSkills,
          hardSkills,
          educations,
          experiences,
          certificates,
          achievements: stories?.map((story) => story?.id) || [],
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async sendPersonalInformation(
    personalData: IProfile,
    profileId?: string
  ) {
    console.log(
      "personalData.namePronunciation",
      personalData.namePronunciation
    );
    let urlAvatar = "";
    if (typeof personalData?.avatar === "object") {
      try {
        urlAvatar = await UploadFileServices.uplaodAvatar(
          personalData?.avatar,
          personalData?.userId
        );
      } catch (error) {
        console.log(error);
      }
    }

    if (personalData?.namePronunciation?.startsWith("blob")) {
      let blob = await fetch(personalData.namePronunciation).then((r) =>
        r.blob()
      );

      const namePath = `namePronunciation/${personalData?.userId}/file`;
      const fileUrl = await UploadFileServices.uploadFile(blob, namePath);
      console.log(fileUrl);
      personalData.namePronunciation = fileUrl;
    }

    try {
      const talentsRef = collection(database, "talents");
      const userRef = doc(talentsRef, personalData?.userId);
      const { title, aboutWords, ...personalDataWithoutTitleAndAboutWords } =
        personalData;

      const profileRef = doc(
        talentsRef,
        personalData?.userId,
        "profiles",
        profileId || personalData?.userId
      );
      // test

      await setDoc(
        userRef,
        {
          ...personalDataWithoutTitleAndAboutWords,
          avatar: urlAvatar || personalData?.avatar,
        },
        { merge: true }
      );

      return await setDoc(
        profileRef,
        {
          aboutWords: personalData?.aboutWords || [],
          title: personalData?.title,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async sendAboutMe(bio: string, uid: string, profileId?: string) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);

      return await setDoc(
        profileRef,
        {
          bio,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async deleteIntroVideo(video: any, uid: string, profileId: string) {
    try {
      if (video) {
        const talentsRef = collection(database, "talents");
        const profileRef = doc(talentsRef, uid, "profiles", profileId);

        return await setDoc(
          profileRef,
          {
            introVideo: "",
          },
          { merge: true }
        );
      }

      // if (uid != profileId) {
      //   await UploadFileServices.removeFileFromStorage(video);
      //   await setDoc(
      //     profileRef,
      //     {
      //       introVideo: "",
      //     },
      //     { merge: true }
      //   );
      // } else {
      //   await setDoc(
      //     profileRef,
      //     {
      //       introVideo: "",
      //     },
      //     { merge: true }
      //   );
      // }
      // }
    } catch (error) {
      console.log(error);
    }
  }

  static async deleteNamePronunciation(audio: string, uid: string) {
    try {
      if (audio) {
        UploadFileServices.removeFileFromStorage(audio).then(() => {
          const talentsRef = collection(database, "talents");
          const userRef = doc(talentsRef, uid);

          return setDoc(
            userRef,
            {
              namePronunciation: "",
            },
            { merge: true }
          );
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  static async sendIntroVideo(video: any, uid: string, profileId?: string) {
    try {
      if (typeof video === "object") {
        const videoPath = `talentIntroductionVideo/${uid}/${
          profileId || uid
        }/${new Date().getTime()}`;
        const fileUrl = await UploadFileServices.uploadFile(video, videoPath);
        video = fileUrl;
      }

      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);

      return await setDoc(
        profileRef,
        {
          introVideo: video,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async sendEducationInformation(
    educationData: IEducation[],
    uid: string,
    profileId?: string
  ) {
    profileId = profileId || uid;
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);

      const educationsWithId = educationData.map((education) => {
        return {
          ...education,
          id: uuidv4(),
        };
      });

      await setDoc(
        profileRef,
        {
          educations: arrayUnion(...educationsWithId),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async sendIndustryExperienceInformation(
    industryData: IIndustryExperience[],
    uid: string,
    profileId?: string
  ) {
    profileId = profileId || uid;
    try {
      const talentsRef = collection(database, "talents");
      const talentRef = doc(talentsRef, uid);
      const profilesRef = collection(talentRef, "profiles");
      const profileRef = doc(profilesRef, profileId);

      return await setDoc(
        profileRef,
        { industryExperiences: arrayUnion(...industryData) },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async sendJobFunctionInformation(
    jobFunctionData: IJobFunction[],
    uid: string,
    profileId?: string
  ) {
    profileId = profileId || uid;
    try {
      const talentsRef = collection(database, "talents");
      const talentRef = doc(talentsRef, uid);
      const profilesRef = collection(talentRef, "profiles");
      const profileRef = doc(profilesRef, profileId);

      const jobFunctionsWithId = jobFunctionData.map((jobFunction) => {
        return {
          ...jobFunction,
          id: uuidv4(),
        };
      });

      // Update the user document with the talentData object using the merge option
      await setDoc(
        profileRef,
        {
          jobFunctions: arrayUnion(...jobFunctionsWithId),
        },
        { merge: true }
      );

      return jobFunctionData;
    } catch (error) {
      console.log(error);
    }
  }

  static async sendExperienceInformation(
    experienceData: IExperience[],
    uid: string,
    profileId?: string
  ) {
    profileId = profileId || uid;
    try {
      const talentsRef = collection(database, "talents");
      const talentRef = doc(talentsRef, uid);
      const profilesRef = collection(talentRef, "profiles");
      const profileRef = doc(profilesRef, profileId);

      await setDoc(
        profileRef,
        {
          experiences: arrayUnion(...experienceData),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async sendExperiencesData(
    industryData: IIndustryExperience[],
    jobFunctionData: IJobFunction[],
    experienceData: IExperience[],
    uid: string
  ) {
    try {
      const promises = [
        this.sendIndustryExperienceInformation(industryData, uid),
        this.sendJobFunctionInformation(jobFunctionData, uid),
        this.sendExperienceInformation(experienceData, uid),
      ];
      return await Promise.all(promises);
    } catch (error) {
      console.log(error);
    }
  }

  static async sendLanguageInformation(
    languageData: ILanguage[],
    uid: string,
    profileId?: string
  ) {
    profileId = profileId || uid;
    try {
      const talentsRef = collection(database, "talents");
      const talentRef = doc(talentsRef, uid);
      const profilesRef = collection(talentRef, "profiles");
      const profileRef = doc(profilesRef, profileId);

      await setDoc(
        profileRef,
        {
          languages: arrayUnion(...languageData),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async sendSoftSkillInformation(
    softSkillsData: ISoftSkills[],
    uid: string,
    profileId?: string
  ) {
    profileId = profileId || uid;
    try {
      const talentsRef = collection(database, "talents");
      const talentRef = doc(talentsRef, uid);
      const profilesRef = collection(talentRef, "profiles");
      const profileRef = doc(profilesRef, profileId);

      await setDoc(
        profileRef,
        {
          softSkills: softSkillsData,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async sendHardSkillInformation(
    hardSkillsData: IHardSkills[],
    uid: string,
    profileId?: string
  ) {
    profileId = profileId || uid;
    try {
      const talentsRef = collection(database, "talents");
      const talentRef = doc(talentsRef, uid);
      const profilesRef = collection(talentRef, "profiles");
      const profileRef = doc(profilesRef, profileId);

      const hardSkillsWithId = hardSkillsData.map((hardSkill) => {
        return {
          ...hardSkill,
          id: uuidv4(),
        };
      });

      await setDoc(
        profileRef,
        {
          hardSkills: arrayUnion(...hardSkillsWithId),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async sendQualificationsData(
    languageData: ILanguage[],
    hardSkillsData: IHardSkills[],
    softSkillsData: ISoftSkills[],
    uid: string
  ) {
    try {
      const promises = [
        this.sendLanguageInformation(languageData, uid),
        this.sendHardSkillInformation(hardSkillsData, uid),
        this.sendSoftSkillInformation(softSkillsData, uid),
        this.updateUserStatus(
          uid,
          process.env.onboardingCompletedStatus as IStatus,
          "talent"
        ),
      ];
      return await Promise.all(promises);
    } catch (error) {
      console.log(error);
    }
  }

  static async addNewAchievement(
    storyId: string,
    uid: string,
    profileId?: string
  ) {
    // profileId = profileId || uid;
    try {
      const talentsRef = collection(database, "talents");
      const talentRef = doc(talentsRef, uid);
      const profilesRef = collection(talentRef, "profiles");
      const profileRef = doc(profilesRef, profileId);

      return await setDoc(
        profileRef,
        {
          achievements: arrayUnion(storyId),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async addNewCertificate(
    { name, institute, issueDate, expireDate, file }: ICertificate,
    uid: string,
    profileId?: string
  ) {
    let certificateImageURL = "";

    if (file && file instanceof Blob) {
      certificateImageURL = await UploadFileServices.uplaodCertificateFile(
        file,
        uid,
        profileId || uid
      );
    }

    const CertificateData = {
      name,
      institute,
      issueDate,
      expireDate,
      file: certificateImageURL
        ? {
            url: certificateImageURL,
            type: file?.type,
            size: file?.size,
            name: file?.name,
          }
        : {},
      id: uuidv4(),
    };

    try {
      profileId = profileId || uid;
      const talentsRef = collection(database, "talents");
      const talentRef = doc(talentsRef, uid);
      const profilesRef = collection(talentRef, "profiles");
      const profileRef = doc(profilesRef, profileId);

      return await setDoc(
        profileRef,
        {
          certificates: arrayUnion(...[CertificateData]),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async editCertificate(
    certificateId: string,
    { name, institute, issueDate, expireDate, image, file }: ICertificate,
    uid: string,
    profileId?: string
  ) {
    try {
      let certificateFileURL = "";
      if (file && file instanceof Blob) {
        certificateFileURL = await UploadFileServices.uplaodCertificateFile(
          file,
          uid,
          profileId || uid
        );
      }

      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);
      const profileDoc = await getDoc(profileRef);

      const userCertificates = profileDoc.data()?.certificates;

      const certificateToEdit = userCertificates.find(
        (certificate: ICertificate) => certificate.id === certificateId
      );

      const editedCertificate = userCertificates.map((certificate: any) => {
        if (certificate.id === certificateId) {
          return {
            ...certificate,
            name,
            institute,
            issueDate,
            expireDate,
            file: certificateFileURL
              ? {
                  url: certificateFileURL,
                  name: file?.name,
                  type: file?.type,
                  size: file?.size,
                }
              : certificate.file,
          };
        }
        return certificate;
      });

      return await setDoc(
        profileRef,
        {
          certificates: editedCertificate,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async editAchievement(
    achievementId: string,
    { category, story }: IAchievementCategory,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);
      const profileDoc = await getDoc(profileRef);

      const userAchievements = profileDoc.data()?.achievements;

      const editedAchievements = userAchievements.map((achievement: any) => {
        if (achievement.id === achievementId) {
          return { ...achievement, category, story };
        }
        return achievement;
      });

      return await setDoc(
        profileRef,
        {
          achievements: editedAchievements,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async editExperience(
    experienceId: string,
    experienceData: IExperience,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);
      const profileDoc = await getDoc(profileRef);
      const userExperiences = profileDoc.data()?.experiences;

      const editedexperiences = userExperiences.map((experience: any) => {
        if (experience.id === experienceId) {
          return { ...experience, ...experienceData };
        }
        return experience;
      });

      return await setDoc(
        profileRef,
        {
          experiences: editedexperiences,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async editEducation(
    educationId: string,
    educationData: IEducation,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);
      const profileDoc = await getDoc(profileRef);
      const userEducations = profileDoc.data()?.educations;

      const editededucations = userEducations.map((education: any) => {
        if (education.id === educationId) {
          return { ...education, ...educationData };
        }
        return education;
      });

      return await setDoc(
        profileRef,
        {
          educations: editededucations,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async editHardSkill(
    skillId: string,
    { name, level, year }: IHardSkills,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);
      const profileDoc = await getDoc(profileRef);
      const userHardSkills = profileDoc.data()?.hardSkills;

      const editedHardSkill = userHardSkills.map((skill: any) => {
        if (skill.id === skillId) {
          return { ...skill, name, level, year };
        }
        return skill;
      });

      return await setDoc(
        profileRef,
        {
          hardSkills: editedHardSkill,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async editIndustryBackground(
    industryBackgroundId: string,
    { name, year }: IIndustryExperience,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);
      const profileDoc = await getDoc(profileRef);

      const userIndustriesBackground = profileDoc.data()?.industryExperiences;

      const editedindustryBackground = userIndustriesBackground.map(
        (industry: IIndustryExperience) => {
          if (industry.id === industryBackgroundId) {
            return { ...industry, name, year };
          }
          return industry;
        }
      );

      return await setDoc(
        profileRef,
        {
          industryExperiences: editedindustryBackground,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async editSoftSkill(
    softSkillId: string,
    { name, level, year, id }: ISoftSkills,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);
      const profileDoc = await getDoc(profileRef);

      const userSoftSkills = profileDoc.data()?.softSkills;

      const editedSoftSkill = userSoftSkills.map((skill: any) => {
        if (skill.id === softSkillId) {
          return { ...skill, name, level, year };
        }
        return skill;
      });

      return await setDoc(
        profileRef,
        {
          softSkills: editedSoftSkill,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async editLanguage(
    languageId: string,
    languageData: ILanguage,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);
      const profileDoc = await getDoc(profileRef);

      const userLanguages = profileDoc.data()?.languages;

      const editedLanguage = userLanguages.map((language: ILanguage) => {
        if (language.id === languageId) {
          return { ...language, ...languageData };
        }
        return language;
      });

      return await setDoc(
        profileRef,
        {
          languages: editedLanguage,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async editJobFunction(
    jobFunctionId: string,
    { name, year }: IJobFunction,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);
      const profileDoc = await getDoc(profileRef);

      const userJobFunctions = profileDoc.data()?.jobFunctions;

      const editedJobFunctionId = userJobFunctions.map((jobFunction: any) => {
        if (jobFunction.id === jobFunctionId) {
          return { ...jobFunction, name, year };
        }
        return jobFunction;
      });

      return await setDoc(
        profileRef,
        {
          jobFunctions: editedJobFunctionId,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async removeHardSkill(
    skill: IHardSkills | undefined,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);

      return await setDoc(
        profileRef,
        {
          hardSkills: arrayRemove(skill),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async removeSoftSkill(
    skill: ISoftSkills,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);

      return await setDoc(
        profileRef,
        {
          softSkills: arrayRemove(skill),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async removeLanguage(
    language: ILanguage,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);

      return await setDoc(
        profileRef,
        {
          languages: arrayRemove(language),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async removeIndustryExperience(
    industry: IIndustryExperience,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);

      return await setDoc(
        profileRef,
        {
          industryExperiences: arrayRemove(industry),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async removeJobFunction(
    jobFunction: IJobFunction,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);

      return await setDoc(
        profileRef,
        {
          jobFunctions: arrayRemove(jobFunction),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async removeAchievement(
    achievementId: string,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);

      return await setDoc(
        profileRef,
        {
          achievements: arrayRemove(achievementId),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async removeExperience(
    experience: IExperience,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);

      return await setDoc(
        profileRef,
        {
          experiences: arrayRemove(experience),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async removeEducation(
    education: IEducation,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);

      return await setDoc(
        profileRef,
        {
          educations: arrayRemove(education),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async removeCertificate(
    certificate: ICertificate,
    uid: string,
    profileId?: string
  ) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);

      // const certificateToRemove = userCertificates.find(
      //   (certificate: ICertificate) => certificate.id === certificateId
      // );

      // if (certificateToRemove?.image) {
      //   await UploadFileServices.removeFileFromStorage(
      //     certificateToRemove.image
      //   );
      // }

      return await setDoc(
        profileRef,
        {
          certificates: arrayRemove(certificate),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  // Job Target
  static async sendJobTargetInformation(
    jobTargetData: IJobTarget,
    uid: string
  ) {
    try {
      const profileRef = collection(database, "talents");
      const userRef = doc(profileRef, uid);

      await setDoc(
        userRef,
        {
          jobTarget: jobTargetData,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async updateUserStatus(
    uid: string,
    status: IStatus,
    userType: string
  ) {
    try {
      let profileRef;
      if (userType === "talent") {
        profileRef = collection(database, "talents");
      } else {
        profileRef = collection(database, "recruiters");
      }
      const userRef = doc(profileRef, uid);

      await setDoc(
        userRef,
        {
          status: status,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }
  static async getCommonData() {
    try {
      // Get reference to Firestore collection
      const collectionRef = collection(database, "commonData");

      // Get all documents in collection
      const snapshot = await getDocs(collectionRef);
      let commonData: any = {};
      snapshot.forEach((doc) => {
        const data = doc.data();
        const key = Object.keys(data)[0];
        if (key) {
          commonData[key] = data[key];
        }
      });
      return commonData;
    } catch (error) {
      console.error(error);
    }
  }

  static async reorderField<T>(
    fieldName: string, // This is the field name in the document (like 'hardSkills')
    itemsArray: T[] | undefined, // Pass the array directly
    uid: string,
    profileId: string | undefined,
    currentIndex: number,
    targetIndex: number
  ): Promise<void> {
    try {
      if (!itemsArray) return;

      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId || uid);

      // Check if indices are within bounds
      if (
        currentIndex < 0 ||
        currentIndex >= itemsArray?.length ||
        targetIndex < 0 ||
        targetIndex >= itemsArray?.length
      ) {
        throw new Error("Index out of bounds");
      }

      // Reordering the array
      const [item] = itemsArray.splice(currentIndex, 1);
      itemsArray.splice(targetIndex, 0, item);

      // Set the reordered array back to Firebase
      await setDoc(profileRef, { [fieldName]: itemsArray }, { merge: true });
    } catch (error) {
      console.error("Error reordering array:", error);
    }
  }
}

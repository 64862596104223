// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage } from "firebase/storage";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";

const IS_STAGING =
  process.env.NEXT_PUBLIC_ENV === "staging" ||
  process.env.NODE_ENV === "development";

const firebaseConfig = {
  apiKey: process.env.firebaseAPIKey,
  authDomain: process.env.firebaseAuthDomain,
  projectId: process.env.firebaseProjectId,
  storageBucket: process.env.firebaseStorageBucket,
  messagingSenderId: process.env.firebaseMessagingSenderId,
  appId: process.env.firebaseAppId,
  measurementId: process.env.firebaseMeasurementId,
};

const firebaseConfigStaging = {
  apiKey: process.env.firebaseAPIKeyStaging,
  authDomain: process.env.firebaseAuthDomainStaging,
  projectId: process.env.firebaseProjectIdStaging,
  storageBucket: process.env.firebaseStorageBucketStaging,
  messagingSenderId: process.env.firebaseMessagingSenderIdStaging,
  appId: process.env.firebaseAppIdStaging,
  measurementId: process.env.firebaseMeasurementIdStaging,
};

// Initialize Firebase
export const app = initializeApp(
  IS_STAGING ? firebaseConfigStaging : firebaseConfig
);
// if (typeof window !== "undefined") {
//   const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaEnterpriseProvider(
//       IS_STAGING
//         ? process.env.recaptchaSiteKeyStaging
//         : process.env.recaptchaSiteKeyProduction
//     ),
//     isTokenAutoRefreshEnabled: true,
//   });
// }

export const database = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

export default onAuthStateChanged;

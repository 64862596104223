import { database } from "@/firebaseConfig";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { TNotification } from "@/types/notification.interface";

export class NotificationServices {
  static subscribeToNotifications(
    userId: string,
    userType: string,
    onNotification: (notifications: TNotification[]) => void
  ): () => void {
    const path =
      userType === "talent"
        ? `talents/${userId}/notifications`
        : `recruiters/${userId}/notifications`;
    const notificationsRef = collection(database, path);

    const unsubscribe = onSnapshot(notificationsRef, (snapshot) => {
      const notifications: TNotification[] = snapshot.docs.map(
        (doc) =>
          ({
            ...doc.data(),
            id: doc.id,
          } as TNotification)
      );
      onNotification(notifications);
    });

    return unsubscribe;
  }

  static async readNotifications(userId: string, userType: string) {
    const notificationsPath =
      userType === "talent"
        ? `talents/${userId}/notifications`
        : `recruiters/${userId}/notifications`;

    try {
      const notificationsRef = collection(database, notificationsPath);
      const unreadNotificationsQuery = query(
        notificationsRef,
        where("read", "==", false)
      );
      const snapshot = await getDocs(unreadNotificationsQuery);

      if (snapshot.empty) {
        console.log("No unread notifications to mark as read.");
        return;
      }

      const batch = writeBatch(database);
      snapshot.docs.forEach((doc) => batch.update(doc.ref, { read: true }));
      await batch.commit();

      console.log("Successfully marked all unread notifications as read.");
    } catch (error) {
      console.error("Failed to mark notifications as read:", error);
    }
  }
}

import { useEffect, useState } from "react";

import { MessageServices } from "@/services/message.services";
import { IMessage } from "@/types/formInfo.interface";

export function useMessageData(userId: string) {
  const [userMessages, setUserMessages] = useState([]);
  const [messageNotRead, setMessageNotRead] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [unsubscribe, setUnsubscribe] = useState(null);

  useEffect(() => {
    if (userId) {
      const unsubscribe = MessageServices.getUserMessages(
        userId,
        setUserMessages,
        setLoading
      );
      setUnsubscribe(unsubscribe);
    }
    return () => unsubscribe;
  }, [userId]);

  useEffect(() => {
    if (userMessages) {
      const messageRead = userMessages.filter(
        (m: IMessage) => "read" in m && !m.read
      );
      setMessageNotRead(messageRead.length);
    } else {
      setMessageNotRead(0);
    }
  }, [userMessages]);

  return { userMessages, messageNotRead, loading };
}

import { IAccessLevelRecruiter } from "@/types/formInfo.interface";

import { useUser } from "@/context/AuthContext";
import { useEffect, useState } from "react";

export function useFeatureAccess(featureRoles: IAccessLevelRecruiter[]) {
  const { currentUser } = useUser();
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (currentUser?.userType === "recruiter") {
      setHasAccess(featureRoles.includes(currentUser?.accessLevel));
    } else {
      setHasAccess(false);
    }
  }, [currentUser, featureRoles]);

  return { hasAccess };
}

import { v4 as uuid } from "uuid";

import { database } from "@/firebaseConfig";
import {
  doc,
  setDoc,
  getDoc,
  onSnapshot,
  collection,
  Timestamp,
} from "firebase/firestore";
import { IMessage, IProfile, IRecruiter } from "@/types/formInfo.interface";

export class MessageServices {
  static async sendMessage(
    sender: IProfile | IRecruiter,
    receiver: IProfile | IRecruiter,
    message: string
  ) {
    const combinedId =
      sender.userId > receiver.userId
        ? sender.userId + receiver.userId
        : receiver.userId + sender.userId;

    try {
      const messageRef = collection(database, "messages");
      const senderMessagesRef = doc(messageRef, sender?.userId);
      const receiverMessagesRef = doc(messageRef, receiver?.userId);

      const senderDoc = await getDoc(senderMessagesRef);
      const receiverDoc = await getDoc(receiverMessagesRef);

      const senderChats = senderDoc.exists() ? senderDoc.data()?.messages : [];
      const receiverChats = receiverDoc.exists()
        ? receiverDoc.data()?.messages
        : [];

      const senderChatIndex = senderChats?.findIndex(
        (chat: IMessage) => chat.id === combinedId
      );
      const receiverChatIndex = receiverChats?.findIndex(
        (chat: IMessage) => chat.id === combinedId
      );

      if (senderChatIndex !== -1) {
        // Chat ID already exists in sender's chats
        const updatedSenderChat = { ...senderChats[senderChatIndex] };
        updatedSenderChat.lastMessage = message;
        updatedSenderChat.date = Timestamp.now();
        updatedSenderChat.messages.push({
          id: uuid(),
          content: message,
          senderId: sender?.userId,
          date: Timestamp.now(),
        });
        senderChats[senderChatIndex] = updatedSenderChat;
      } else {
        // Chat ID doesn't exist in sender's chats, create a new item
        senderChats.push({
          id: combinedId,
          date: Timestamp.now(),
          userInfo: {
            uid: receiver.userId,
            firstName: receiver.firstName,
            lastName: receiver.lastName,
            avatar: receiver.avatar,
          },
          lastMessage: message,
          messages: [
            {
              id: uuid(),
              content: message,
              senderId: sender?.userId,
              date: Timestamp.now(),
            },
          ],
        });
      }

      if (receiverChatIndex !== -1) {
        // Chat ID already exists in receiver's chats
        const updatedReceiverChat = { ...receiverChats[receiverChatIndex] };
        updatedReceiverChat.lastMessage = message;
        updatedReceiverChat.date = Timestamp.now();
        updatedReceiverChat.read = false;
        updatedReceiverChat.messages.push({
          id: uuid(),
          content: message,
          senderId: sender?.userId,
          date: Timestamp.now(),
        });
        receiverChats[receiverChatIndex] = updatedReceiverChat;
      } else {
        // Chat ID doesn't exist in receiver's chats, create a new item
        receiverChats.push({
          id: combinedId,
          date: Timestamp.now(),
          read: false,
          userInfo: {
            uid: sender?.userId,
            firstName: sender?.firstName,
            lastName: sender?.lastName,
            avatar: sender?.avatar,
          },
          lastMessage: message,
          messages: [
            {
              id: uuid(),
              content: message,
              senderId: sender?.userId,
              date: Timestamp.now(),
            },
          ],
        });
      }

      // Update sender's chats array
      await setDoc(
        senderMessagesRef,
        { messages: senderChats },
        { merge: true }
      );

      // Update receiver's chats array
      await setDoc(
        receiverMessagesRef,
        { messages: receiverChats },
        { merge: true }
      );
    } catch (error) {
      // Handle any errors
      console.log(error);
    }
  }

  static async getUserMessages(
    id: string,
    setUserMessagesData: (userData: any) => void,
    setLoading?: (loading: boolean) => void
  ) {
    try {
      const messagesRef = collection(database, "messages");
      const userMessagesRef = doc(messagesRef, id);

      const unsubscribe = onSnapshot(userMessagesRef, (snapshot) => {
        const userMessagesData = snapshot.data()?.messages;
        setLoading && setLoading(false);
        setUserMessagesData(userMessagesData);
      });
      return unsubscribe;
    } catch (error) {
      setLoading && setLoading(true);
      console.log(error);
    }
  }

  static async readMessage(userId: string, messageId: string) {
    const messageRef = collection(database, "messages");
    const userMessagesRef = doc(messageRef, userId);
    const messageDoc = await getDoc(userMessagesRef);
    const message = messageDoc.data()?.messages;
    const readMessage = message?.map((m: any) => {
      if (m.id === messageId) {
        return { ...m, read: true };
      }
      return m;
    });

    const data = {
      messages: readMessage,
    };

    return await setDoc(userMessagesRef, data, { merge: true });
  }
}

import { storage } from "@/firebaseConfig";

import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

export class UploadFileServices {
  static uplaodAvatar(image: Blob | Uint8Array | ArrayBuffer, userId: string) {
    const imageRef = ref(storage, `avatar/${userId}`);
    return uploadBytes(imageRef, image)
      .then(() => {
        return getDownloadURL(imageRef);
      })
      .then((url) => {
        return url;
      })
      .catch((error) => {
        throw error;
      });
  }

  static uploadCompanyLogo(
    image: Blob | Uint8Array | ArrayBuffer,
    companyName: string
  ) {
    const imageRef = ref(storage, `company/${companyName}`);
    return uploadBytes(imageRef, image)
      .then(() => {
        return getDownloadURL(imageRef);
      })
      .then((url) => {
        return url;
      })
      .catch((error) => {
        throw error;
      });
  }

  static uplaodCertificateFile(image: any, uid: string, profileId: string) {
    const fileName = new Date().getTime();
    const imageRef = ref(
      storage,
      `certificates/${uid}/${profileId}/${fileName}`
    );
    return uploadBytes(imageRef, image)
      .then(() => {
        return getDownloadURL(imageRef);
      })
      .then((url) => {
        return url;
      })
      .catch((error) => {
        throw error;
      });
  }

  static uploadFile(file: Blob | Uint8Array | ArrayBuffer, path: string) {
    const fileRef = ref(storage, path);
    return uploadBytes(fileRef, file)
      .then(() => {
        return getDownloadURL(fileRef);
      })
      .then((url) => {
        return url;
      })
      .catch((error) => {
        throw error;
      });
  }

  static removeFileFromStorage(file: any) {
    const imageRef = ref(storage, file);
    return deleteObject(imageRef);
  }
}

import React from "react";
import { TNotification } from "@/types/notification.interface";
import Link from "next/link";

const NotificationItem = ({
  notification,
  noBorder,
}: {
  notification: TNotification;
  noBorder: boolean;
}) => {
  return (
    <Link
      href={(notification?.body?.href || "") as string}
      target="_blank"
      className="text-primary font-bold no-underline"
    >
      <div
        className={`p-4 flex align-items-center justify-content-between surface-border w-full hover:bg-primary-50 ${
          !noBorder ? "border-bottom-1" : ""
        }`}
      >
        <div className="flex flex-column">
          {notification?.body?.title && (
            <span className="text-900 font-bold mb-2">
              {notification?.body?.title}
            </span>
          )}
          {notification?.body?.message && (
            <span className="text-900 font-medium mb-2">
              {notification?.body?.message}
            </span>
          )}
        </div>
      </div>
    </Link>
  );
};

export default NotificationItem;

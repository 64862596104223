import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  FacebookAuthProvider,
  GoogleAuthProvider,
  GithubAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import { collection, doc, getDoc, setDoc, Timestamp } from "firebase/firestore";

import { auth, database } from "@/firebaseConfig";

import { OnboardingServices } from "./onboarding.services";
import { IStatus } from "@/types/formInfo.interface";

const isServer = typeof window === "undefined";
export class AuthServices {
  static async registerWithEmailAndPassword(
    email: string,
    password: string,
    userType: string
  ) {
    try {
      const cred = await createUserWithEmailAndPassword(auth, email, password);
      if (cred.user) {
        const data = {
          userId: cred.user.uid,
          email: cred.user.email,
          status: process.env.registerStatus,
          createdAt: Timestamp.now(),
          lastLogin: Timestamp.now(),
        };

        // Create new document for new user in profile collcetion
        let profileRef;
        if (userType === "talent") {
          profileRef = await collection(database, "talents");
        } else {
          profileRef = await collection(database, "recruiters");
        }
        const userRef = await doc(profileRef, cred.user.uid);
        await setDoc(userRef, data, { merge: true });
      }
    } catch (error) {
      throw error;
    }
  }

  static async signWithEmailAndPasswordTalent(email: string, password: string) {
    try {
      if (!isServer) {
        await localStorage.setItem("userType", "talent");
      }

      const usersRef = collection(database, "talents");
      await signInWithEmailAndPassword(auth, email, password);
      const profileRef = doc(usersRef, auth.currentUser?.uid);
      const profileDoc = await getDoc(profileRef);

      //TODO
      if (profileDoc.exists()) {
        setDoc(profileRef, { lastLogin: Timestamp.now() }, { merge: true });

        const completedProfile =
          profileDoc.data().completedProfile ||
          (!!profileDoc.data()?.status &&
            profileDoc.data()?.status !== process.env.registerStatus &&
            profileDoc.data()?.status !== process.env.onboardingStatus);

        if (
          auth.currentUser?.emailVerified &&
          profileDoc.data()?.status === process.env.registerStatus
        ) {
          await OnboardingServices.updateUserStatus(
            auth.currentUser?.uid,
            process.env.verifiedStatus as IStatus,
            "talent"
          );
        }

        if (auth.currentUser && !auth.currentUser?.emailVerified) {
          window.location.href = `/auth/verification-email`;
        } else if (completedProfile) {
          window.location.href = "/dashboard/talent";
        } else if (!completedProfile) {
          window.location.href = "/onboarding/talent";
        }
      } else {
        this.logOut();
        throw {
          notExist: true,
          message: "You don't have account as a talent",
        };
      }
    } catch (error) {
      throw error;
    }
  }

  static async signWithEmailAndPasswordRecruiter(
    email: string,
    password: string
  ) {
    try {
      if (!isServer) {
        await localStorage.setItem("userType", "recruiter");
      }

      await signInWithEmailAndPassword(auth, email, password);

      const usersRef = collection(database, "recruiters");
      const profileRef = doc(usersRef, auth.currentUser?.uid);
      const profileDoc = await getDoc(profileRef);
      const status = await profileDoc.data()?.status;

      //TODO
      if (profileDoc.exists()) {
        setDoc(profileRef, { lastLogin: Timestamp.now() }, { merge: true });
        console.log(auth.currentUser);
        if (
          auth.currentUser?.emailVerified &&
          status === process.env.registerStatus
        ) {
          await OnboardingServices.updateUserStatus(
            auth.currentUser?.uid,
            process.env.verifiedStatus as IStatus,
            "recruiter"
          );
          window.location.href = "/onboarding/recruiter";
        }

        if (auth.currentUser && !auth.currentUser?.emailVerified) {
          window.location.href = "/auth/verification-email";
        }

        if (
          status === process.env.recruiterVerifiedStatus ||
          status === process.env.recruiterOnboardingStatus
        ) {
          window.location.href = "/onboarding/recruiter";
        }

        if (status === process.env.recruiterPendingStatus) {
          window.location.href = "/dashboard/recruiter/pending";
        }

        if (status === process.env.recruiterApprovedStatus) {
          window.location.href = "/dashboard/recruiter";
        }

        if (status === process.env.recruiterRejectedStatus) {
          window.location.href = "/dashboard/recruiter/no-access";
        }
      } else {
        this.logOut();
        throw {
          notExist: true,
          message: "You don't have account as a recruiter",
        };
      }
    } catch (error) {
      throw error;
    }
  }

  static async signInWithGoogle(userType: string = "talent") {
    try {
      //TODO
      if (!isServer) {
        await localStorage.setItem("userType", userType);
      }
      const provider = new GoogleAuthProvider();
      const cred = await signInWithPopup(auth, provider);
      const usersRef = await collection(database, `${userType}s`); // The collection name is talents or recruiters
      const profileRef = await doc(usersRef, auth.currentUser?.uid);
      const profileDoc = await getDoc(profileRef);

      if (cred.user) {
        if (!profileDoc.exists()) {
          const data = {
            userId: cred.user.uid,
            email: cred.user.email,
            status: process.env.verifiedStatus,
            createdAt: Timestamp.now(),
            lastLogin: Timestamp.now(),
          };
          await setDoc(profileRef, data, { merge: true });
          window.location.href = `/onboarding/${userType}`;
        } else {
          const completedProfile =
            profileDoc.data().completedProfile ||
            (!!profileDoc.data()?.status &&
              profileDoc.data()?.status !== process.env.registerStatus &&
              profileDoc.data()?.status !== process.env.onboardingStatus);
          await setDoc(
            profileRef,
            {
              lastLogin: Timestamp.now(),
            },
            { merge: true }
          );
          if (completedProfile) {
            window.location.href = `/dashboard/${userType}`;
          } else {
            window.location.href = `/onboarding/${userType}`;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  static async signInWithFacebook(userType: string = "talent") {
    try {
      //TODO
      if (!isServer) {
        await localStorage.setItem("userType", userType);
      }
      const provider = new FacebookAuthProvider();
      const cred = await signInWithPopup(auth, provider);
      const usersRef = collection(database, `${userType}s`); // The collection name is talents or recruiters
      const profileRef = doc(usersRef, auth.currentUser?.uid);
      const profileDoc = await getDoc(profileRef);

      if (cred.user) {
        if (!profileDoc.exists()) {
          const data = {
            userId: cred.user.uid,
            email: cred.user.email,
            status: process.env.registerStatus,
            createdAt: Timestamp.now(),
            lastLogin: Timestamp.now(),
          };
          // Create new document for new user in profile collcetion
          let profileRef;
          if (userType === "talent") {
            profileRef = await collection(database, "talents");
          } else {
            profileRef = await collection(database, "recruiters");
          }
          const userRef = await doc(profileRef, cred.user.uid);
          await setDoc(userRef, data, { merge: true });
          window.location.href = `/onboarding/${userType}`;
        } else {
          const completedProfile =
            profileDoc.data().completedProfile ||
            (!!profileDoc.data()?.status &&
              profileDoc.data()?.status !== process.env.registerStatus &&
              profileDoc.data()?.status !== process.env.onboardingStatus);

          if (
            auth.currentUser?.emailVerified &&
            profileDoc.data()?.status === process.env.registerStatus
          ) {
            await OnboardingServices.updateUserStatus(
              auth.currentUser?.uid,
              process.env.verifiedStatus as IStatus,
              userType
            );
          }

          if (completedProfile) {
            window.location.href = `/dashboard/${userType}`;
          } else {
            window.location.href = `/onboarding/${userType}`;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  static async signInWithGithub(userType: string = "talent") {
    try {
      //TODO
      if (!isServer) {
        await localStorage.setItem("userType", userType);
      }
      const provider = new GithubAuthProvider();
      const cred = await signInWithPopup(auth, provider);
      const usersRef = collection(database, "talents");
      const profileRef = doc(usersRef, auth.currentUser?.uid);
      const profileDoc = await getDoc(profileRef);
      if (cred.user) {
        if (!profileDoc.exists()) {
          const data = {
            userId: cred.user.uid,
            email: cred.user.email,
            status: process.env.registerStatus,
            createdAt: Timestamp.now(),
            lastLogin: Timestamp.now(),
          };

          await setDoc(profileRef, data, { merge: true });
          window.location.href = `/onboarding/${userType}`;
        } else {
          await setDoc(
            profileRef,
            {
              lastLogin: Timestamp.now(),
            },
            { merge: true }
          );
          const completedProfile =
            profileDoc.data().completedProfile ||
            (!!profileDoc.data()?.status &&
              profileDoc.data()?.status !== process.env.registerStatus &&
              profileDoc.data()?.status !== process.env.onboardingStatus);
          if (
            auth.currentUser?.emailVerified &&
            profileDoc.data()?.status === process.env.registerStatus
          ) {
            await OnboardingServices.updateUserStatus(
              auth.currentUser?.uid,
              process.env.verifiedStatus as IStatus,
              userType
            );
          }
          if (completedProfile) {
            window.location.href = `/dashboard/${userType}`;
          } else {
            window.location.href = `/onboarding/${userType}`;
          }
        }
      }
    } catch (error) {
      throw error;
    }
  }

  static async sendVerificationEmail() {
    const { currentUser } = auth;
    return sendEmailVerification(currentUser);
  }

  static async verifyEmail(oobCode: string) {
    if (oobCode) {
      var url = `https://identitytoolkit.googleapis.com/v1/accounts:update?key=${process.env.firebaseAPIKey}`;

      try {
        return await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            oobCode,
          }),
        });
      } catch (error) {
        throw error;
      }
    }
  }

  static async changePassword(oobCode: string, newPassword: string) {
    if (oobCode) {
      var url = `https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${process.env.firebaseAPIKey}`;
      try {
        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            oobCode,
            newPassword,
          }),
        });
      } catch (error) {
        throw error;
      }
    }
  }

  static async resetPassword(email: string) {
    return sendPasswordResetEmail(auth, email);
  }

  static async getIdToken() {
    try {
      const idToken = await auth?.currentUser?.getIdToken(
        /* forceRefresh */ true
      );
      return idToken;
    } catch (error) {
      // Handle error here
      console.error("Error getting ID token:", error);
      return null; // Or some other appropriate value to indicate an error
    }
  }

  static async logOut() {
    if (!isServer) {
      localStorage.removeItem("user");
    }
    return signOut(auth);
  }
}

import { useUser } from "@/context/AuthContext";
import { NotificationServices } from "@/services/notification.services";
import { TNotification } from "@/types/notification.interface";
import { useEffect, useState } from "react";

function useNotifications() {
  const { currentUser } = useUser();
  const [notifications, setNotifications] = useState<TNotification[]>([]);
  const [unreadNotifications, setUnreadNotifications] = useState<
    TNotification[]
  >([]);

  useEffect(() => {
    if (!currentUser?.uid || !currentUser?.userType) {
      setNotifications([]); // Reset notifications or handle as needed when user is not defined
      return;
    }

    // Subscribe to notifications for the current user
    const unsubscribe = NotificationServices.subscribeToNotifications(
      currentUser.uid,
      currentUser.userType,
      (newNotifications) => {
        setNotifications(newNotifications);
        const newUnreadNotifications = newNotifications.filter((n) => !n.read);
        setUnreadNotifications(newUnreadNotifications);
      }
    );

    // Cleanup subscription on component unmount or if currentUser changes
    return () => unsubscribe();
  }, [currentUser?.uid, currentUser?.userType]); // Dependency array includes properties of currentUser

  return { notifications, unreadNotifications };
}

export default useNotifications;

import { createContext, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import isFunction from "lodash/isFunction";

import { auth } from "@/firebaseConfig";
import onAuthStateChanged from "@/firebaseConfig";

const isServer = typeof window === "undefined";

const AuthContext = createContext();

export const useUser = () => useContext(AuthContext);

export const AuthContextProvider = ({ children, setUser }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      // Check for user data in local storage
      const storedUserData = localStorage.getItem("user");
      const userType = localStorage.getItem("userType");

      if (storedUserData) {
        const user = JSON.parse(storedUserData);
        setUser({
          ...user,
          userType: userType,
          isLogin: true,
        });
        setCurrentUser({
          ...user,
          userType: userType,
        });
        setIsLoading(false);
      }
    };

    fetchUserData();

    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        const accessLevel = firebaseUser?.reloadUserInfo?.customAttributes
          ? JSON.parse(firebaseUser.reloadUserInfo.customAttributes)
              ?.accessLevel
          : undefined;

        const user = {
          ...firebaseUser,
          userType: localStorage.getItem("userType"),
          accessLevel: accessLevel,
          isLogin: true,
        };

        localStorage.setItem("user", JSON.stringify(user));
        setUser(user);
        setCurrentUser(user);
      } else {
        localStorage.removeItem("user");
        localStorage.removeItem("userType");
        setUser({ isLogin: false });
        setCurrentUser(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe(); // Clean up the subscription
  }, [setUser, setCurrentUser, setIsLoading, auth]);

  if (isLoading) {
    return "Loading";
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser: isServer ? null : currentUser,
        isLogin: currentUser?.emailVerified,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import { useEffect, useState } from "react";

import { ProfileServices } from "@/services/profile.services";
import { IProfile, IRecruiter } from "@/types/formInfo.interface";

export function useUserData(uid: string, usertype: string, profileId?: string) {
  const [loading, setLoading] = useState(false);
  const [unsubscribe, setUnsubscribe] = useState<any>(null);
  const [userData, setUserData] = useState<IProfile | IRecruiter>();

  const completedProfile = Boolean(
    userData?.completedProfile ||
      (!!userData?.status &&
        userData?.status !== process.env.registerStatus &&
        userData?.status !== process.env.onboardingStatus &&
        userData?.status !== process.env.verifiedStatus)
  );

  useEffect(() => {
    let unsubscribeFn: any;

    if (usertype === "talent") {
      if (uid) {
        unsubscribeFn = ProfileServices.getprofileData(
          uid,
          profileId || uid,
          setUserData,
          setLoading
        );
      }
    }

    setUnsubscribe(unsubscribeFn);

    return () => unsubscribeFn;
  }, [uid, usertype, profileId]);

  useEffect(() => {
    if (usertype === "recruiter") {
      setLoading(true);
      ProfileServices.getRecruiterProfileData(uid || "").then((result) => {
        setUserData(result as IRecruiter);
        setLoading(false);
      });
      //
    }
  }, [usertype, uid]);

  return { userData, completedProfile, loading };
}

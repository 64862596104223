import React, { FC, ReactHTMLElement, useState } from "react";
import { Dialog } from "primereact/dialog";

interface TermsAndConditionsModalProps {
  onHide: () => void;
  visible: boolean;
}

const TermsAndConditionsModal: FC<TermsAndConditionsModalProps> = ({
  visible,
  onHide,
}) => {
  return (
    <Dialog
      draggable={false}
      blockScroll={true}
      visible={visible}
      onHide={onHide}
      header="Terms And Conditions"
      className="w-11 lg:w-6"
    >
      <p className="line-height-3">
        <span className="font-bold">portfolii - Terms of Use</span>
        <br />
        <br />
        Last Updated: November 21, 2023
        <br />
        <br />
        Welcome to portfolii, operated by AlertPass Inc. ("we," "our," or "us").
        These Terms of Use ("Terms") govern your use of our job marketplace
        application (the "App"). By accessing or using the App, you agree to
        comply with and be bound by these Terms. If you do not agree with these
        Terms, please do not use the App.
        <br />
        <br />
        <span className="font-bold">1. Use of the App</span>
        <br />
        <br />
        a. Eligibility: You must be at least 16 years old to use the App. By
        using the App, you represent and warrant that you meet this age
        requirement.
        <br />
        <br />
        b. Account Creation: To access certain features of the App, you may need
        to create an account. You are responsible for maintaining the
        confidentiality of your account information and for all activities that
        occur under your account.
        <br />
        <br />
        <span className="font-bold">2. User Content</span>
        <br />
        <br />
        a. Responsibility: You are solely responsible for any content you submit
        or post on the App, including job applications, messages, and comments.
        You agree not to post content that violates our Community Guidelines or
        any applicable laws.
        <br />
        <br />
        b. Ownership: You retain ownership of your content. By posting content
        on the App, you grant us a worldwide, non-exclusive, royalty-free, and
        transferable license to use, modify, reproduce, distribute, and display
        your content as necessary to provide the App's services.
        <br />
        <br />
        <span className="font-bold">3. Prohibited Conduct</span>
        <br />
        <br />
        You agree not to: a. Use the App for any unlawful purpose or in
        violation of these Terms.
        <br />
        <br />
        b. Impersonate any person or entity or falsely state or misrepresent
        your affiliation with any person or entity.
        <br />
        <br />
        c. Interfere with or disrupt the operation of the App or the servers or
        networks connected to it.
        <br />
        <br />
        d. Attempt to gain unauthorized access to the App or its related systems
        or networks.
        <br />
        <br />
        e. Engage in any form of harassment, hate speech, or discrimination on
        the App.
        <br />
        <br />
        <span className="font-bold">4. Privacy</span>
        <br />
        <br />
        Your use of the App is also governed by our Privacy Policy. Please
        review our Privacy Policy to understand how we collect, use, and protect
        your personal information.
        <br />
        <br />
        <span className="font-bold"> 5. Intellectual Property</span>
        <br />
        <br />
        All content, trademarks, logos, and intellectual property on the App are
        the property of AlertPass Inc. or its licensors. You may not use,
        reproduce, or distribute any of this content without our express
        permission.
        <br />
        <br />
        <span className="font-bold">6. Prohibition of Web Scraping</span>
        <br />
        <br />
        You agree not to engage in web scraping or any automated data collection
        activities on the App without our express written consent. Unauthorized
        web scraping is a violation of these Terms and may result in the
        suspension or termination of your access to the App, as well as legal
        action, if necessary.
        <br />
        <br />
        <span className="font-bold">7. Prohibition of False Identities</span>
        <br />
        <br />
        You agree not to create a false identity or misrepresent your identity
        on the App. Impersonation or the use of inaccurate information is
        strictly prohibited and may result in the suspension or termination of
        your account, as well as legal action, if necessary.
        <br />
        <br />
        <span className="font-bold">
          8. Prohibition of Unauthorized Use of Information
        </span>
        <br />
        <br />
        You agree not to copy, use, disclose, or distribute any information
        obtained from our application without our express written consent.
        Unauthorized use or dissemination of information from the App is
        strictly prohibited and may result in legal action and the termination
        of your account.
        <br />
        <br />
        <span className="font-bold">
          9. Protection of Intellectual Property
        </span>
        <br />
        <br />
        By using the App, you acknowledge and agree that all rights, title, and
        interest in and to the App and its content are owned or licensed by
        AlertPass Inc. You further agree not to engage in any activities that
        may infringe upon or misappropriate our intellectual property rights.
        <br />
        <br />
        <span className="font-bold">
          10. Prohibition of Reverse Engineering
        </span>
        <br />
        <br />
        You agree not to reverse engineer, decompile, disassemble, or attempt to
        derive the source code or underlying algorithms of the App or any part
        thereof. Reverse engineering of the App is strictly prohibited and may
        result in legal action and the termination of your account.
        <br />
        <br />
        <span className="font-bold">
          11. Disclaimer for Use of AI-Generated Content
        </span>
        <br />
        <br />
        You acknowledge and agree that certain content and items on the
        portfolii application may be generated by artificial intelligence (AI)
        algorithms. This includes, but is not limited to, job recommendations,
        skill suggestions, and other automated features provided by the App.
        <br />
        <br />
        a. Accuracy and Completeness: While we strive to provide accurate and
        up-to-date AI-generated content, you understand that AI algorithms may
        have limitations and may produce content that is not entirely
        error-free, accurate, or complete.
        <br />
        <br />
        b. Review of AI-Generated Content: It is your responsibility to review
        all AI-generated content before relying on it for any purpose, including
        but not limited to job applications, portfolio sharing, or any other
        professional or personal use.
        <br />
        <br />
        c. Errors and Outdated Information: We do not guarantee the accuracy,
        completeness, or timeliness of AI-generated content. You agree that we
        shall not be held liable for any errors, omissions, or outdated
        information in such content.
        <br />
        <br />
        d. User Verification: Users are encouraged to independently verify the
        information provided by AI-generated content before making any decisions
        based on such information.
        <br />
        <br />
        e. No Warranty: We make no warranty, express or implied, regarding the
        performance or results that may be obtained from the use of AI-generated
        content on the App.
        <br />
        <br />
        f. Modification or Discontinuation: We reserve the right to modify,
        discontinue, or suspend the provision of AI-generated content at any
        time, without prior notice.
        <br />
        <br />
        By using the App, you acknowledge and accept that AI-generated content
        is provided "as is" and that you will exercise caution and diligence in
        relying on such content for any purpose.
        <br />
        <br />
        <span className="font-bold">12. Termination</span>
        <br />
        <br />
        We reserve the right to suspend or terminate your access to the App at
        our discretion, without notice, for any reason, including if you violate
        these Terms.
        <br />
        <br />
        <span className="font-bold">13. Disclaimer of Warranties</span>
        <br />
        <br />
        The App is provided "as is" and "as available." We make no warranties or
        representations about the accuracy or completeness of the content on the
        App or the availability of the App for any purpose.
        <br />
        <br />
        <span className="font-bold">14. Limitation of Liability</span>
        <br />
        <br />
        To the fullest extent permitted by law, we shall not be liable for any
        indirect, incidental, special, consequential, or punitive damages, or
        any loss of profits or revenues.
        <br />
        <br />
        <span className="font-bold">15. Governing Law</span>
        <br />
        <br />
        These Terms are governed by and construed in accordance with the laws of
        Ontario (Canada). Any disputes arising out of or relating to these Terms
        will be subject to the exclusive jurisdiction of the courts in Ontario
        (Canada).
        <br />
        <br />
        <span className="font-bold">16. Changes to Terms</span>
        <br />
        <br />
        We may update these Terms from time to time. Any changes will be posted
        on this page with the updated "Last Updated" date.
        <br />
        <br />
        <span className="font-bold">17. Contact Us</span>
        <br />
        <br />
        If you have any questions or concerns about these Terms, please contact
        us at talent@alertpass.com.
        <br />
        <br />
        By using the App, you acknowledge that you have read and agree to these
        Terms of Use. If you do not agree with the terms of these Terms, please
        do not use the App.
      </p>
    </Dialog>
  );
};

export default TermsAndConditionsModal;

export function generateRandomId(length?: number) {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  if (length) {
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  } else {
    return Math.random().toString(36).substring(2);
  }
}
